import {
    Popover,
    Paper,
    IconButton,
    Divider,
    DialogTitle,
    DialogContent,
    DialogActions,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { themeBackgroundPrimaryColor } from '../../utilities/colorTheme';

const CustomPopover = ({
    anchorEl,
    open,
    onClose,
    headerText,
    children,
    footerButtons,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const popoverContentStyle = {
        overflow: 'hidden',
        maxHeight: '80vh',
        width: 'auto',
        height: 'auto',
        position: 'relative', // Add position to allow z-index
    };

    const backdropStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black
        zIndex: 1300, // Ensure backdrop is below the popover
        display: 'block',
        opacity: open ? 1 : 0, // Set opacity based on open state
        transition: 'opacity 0.10s', // Smooth transition for opacity
    };

    return (
        <div>
            {open && <div style={backdropStyle} onClick={onClose} />}
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={onClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Paper style={popoverContentStyle}>
                    <DialogTitle
                        sx={{
                            backgroundColor: themeBackgroundPrimaryColor,
                            color: '#fff',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        {headerText}
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={onClose}
                            aria-label="close"
                            sx={{
                                transition: 'background-color 0.3s, color 0.3s',
                                '&:hover': {
                                    color: 'grey',
                                    backgroundColor: '#fff',
                                },
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <Divider />
                    <DialogContent
                        className="dialog-content custom-scrollbar"
                        style={popoverContentStyle}
                    >
                        <div>{children}</div>
                    </DialogContent>
                    <Divider />
                    <DialogActions
                        sx={{
                            backgroundColor: '#f4f4f4',
                        }}
                    >
                        {footerButtons}
                    </DialogActions>
                </Paper>
            </Popover>
        </div>
    );
};

export default CustomPopover;
