// React
import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// MUI
import {
	Paper,
	Link,
	Grid,
	TextField,
	Autocomplete,
	Typography,
	Stack,
	useMediaQuery,
	useTheme,
	Skeleton,
	Pagination
} from '@mui/material';
import { styled } from "@mui/material/styles";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

// Components
import { CreateButton, DeleteButton, SaveButton } from '../../../components/commons/Buttons';
import SelectInput from '../../../components/commons/SelectInput';
import TextInput from '../../../components/commons/TextInput';
import MUIDatePicker from '../../../components/commons/DatePicker/DatePicker';
import Table from "../../../components/commons/Table/Table";
import CustomDialogueMedium from '../../../components/commons/CustomDialogueMedium';
import CustomDialogueSmall from '../../../components/commons/CustomDialogueSmall';

// Others
import { toast } from 'react-toastify';
import service from './service';

const StyledPaper = styled(Paper)({
	width: '100%',
	minHeight: '200px',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'flex-start', // Align items to the left
	alignItems: 'flex-start', // Align items to the left
	gap: '16px',
	border: '1px solid var(--grey-300, #E0E0E0)',
	background: 'var(--grey-ff, #FFF)',
	padding: '16px 24px',
	borderRadius: '8px 8px 0px 0px',
});

const StyledLink = styled(Link)({
	cursor: 'pointer',
	textDecoration: 'none', // Remove underline
	fontWeight: 'bold', // Add font weight
	display: 'flex', // Add this to align the icon and text
	alignItems: 'center', // Add this to align the icon and text
});

export const allUserRoles = [
	{ key: 'superAdmin', name: 'Super Admin' },
	{ key: 'technicalAdmin', name: 'Technical Admin' },
	{ key: 'technician', name: 'Technician' },
	{ key: 'customerAdmin', name: 'Customer Admin' },
	{ key: 'customerUser', name: 'Customer User' },
	{ key: 'vendorAdmin', name: 'Vendor Admin' },
	{ key: 'vendorUser', name: 'Vendor User' },
];

const tableColumns = [
	{ field: "label", header: "Input Label", type: "text", colSize: "medium" },
	{ field: "inputType", header: "Input Type", type: "text", colSize: "medium" },
	{ field: "value", header: "Default Value", type: "text", colSize: "medium" },
	{ field: "actions", header: "Edit", type: "button", actionType: 'edit', colSize: "small" },
	{ field: "actions", header: "Delete", type: "button", actionType: 'delete', colSize: "small" },
];

const mobileOnlyColumns = [
	{ field: "label", header: "Input Label", type: "text", colSize: "medium" },
	{ field: "inputType", header: "Input Type", type: "text", colSize: "medium" },
	{ field: "value", header: "Default Value", type: "text", colSize: "medium" },
	{ field: "actions", header: "Edit", type: "button", actionType: 'edit', colSize: "small" },
	{ field: "actions", header: "Delete", type: "button", actionType: 'delete', colSize: "small" },
];

function CustomFields() {
	const navigate = useNavigate();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const [addDialogueBoxOpen, setAddDialogueBoxOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [inputData, setInputData] = useState({
		id: '',
		label: '', // STRING: ipAddress, anyDeskUserName etc..
		inputType: '', // ENUM: longText, shortText, date, number 
		permissions: [],
		value: ''
	});

	const [queryData, setQueryData] = useState({
		label: '',
		inputType: '',
		page: 1,
		limit: 150,
		totalPage: '',
		totalCount: ''
	});

	const [tableData, setTableData] = useState([]);

	const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
    const [itemToDeleteAfterConfirm, setItemToDeleteAfterConfirm] = useState(null);

	useEffect(() => {
		getCustomFieldsWithQueryPagination();
	},[])

	const handleLabelChange = useCallback((e) => {
		setInputData(prevData => ({ ...prevData, label: e.target.value }));
	}, []);

	const handleInputTypeChange = useCallback((e) => {
		setInputData(prevData => ({ ...prevData, inputType: e.target.value }));
	}, []);

	const handlePermissionsChange = useCallback((event, newValue) => {
		setInputData(prevData => ({ ...prevData, permissions: newValue }));
	}, []);

	const handleCreateOrUpdate = async () => {
		const fields = ['label', 'inputType', 'permissions'];
		const missingFields = fields.filter(field => !inputData[field] || !inputData[field].length);

		if (missingFields.length) {
			toast.error(`The following mandatory fields are missing: ${missingFields.join(', ')}`, {
				position: toast.POSITION.TOP_CENTER,
				autoClose: true,
				closeOnClick: false,
				draggable: true,
			});
			return;
		};
		setIsLoading(true);
		let response;
		try {
			if (inputData.id) {
				response = await service.updateCustomFieldValues({
					...inputData,
					permissions: inputData.permissions.map(item => item.key)
				});
			} else {
				response = await service.createCustomFieldValues({
					...inputData,
					permissions: inputData.permissions.map(item => item.key)
				});
			}
			if (response.status === 200 || response.status === 201) {
				toast.success(`Successfully ${response.status === 200 ? 'updated' : 'created'} the custom field.`, {
					position: toast.POSITION.TOP_CENTER,
					autoClose: true,
					closeOnClick: false,
					draggable: true,
				});
				getCustomFieldsWithQueryPagination();
				setAddDialogueBoxOpen(false);
				setInputData({
					id: '',
					label: '', // STRING: ipAddress, anyDeskUserName etc..
					inputType: '', // ENUM: longText, shortText, date, number 
					permissions: [],
					value: ''
				})
			} else {
				toast.error(response.data.message || `An error occurred while ${response.status === 200 ? 'updating' : 'adding'}  the custom field.`, {
					position: toast.POSITION.TOP_CENTER,
					autoClose: true,
					closeOnClick: false,
					draggable: true,
				});
			}
		} catch (error) {
			toast.error(`${error.message || 'An error occurred while making the request'}`, {
				position: toast.POSITION.TOP_CENTER,
				autoClose: true,
				closeOnClick: false,
				draggable: true,
			});
		} finally {
			setIsLoading(false);
		}
	}

	const getCustomFieldsWithQueryPagination = async (page = queryData.page, label = queryData.label, inputType = queryData.inputType) => {
		setIsLoading(true);
		const response = await service.getCustomFields({ ...queryData, page, label, inputType });
		if (response.status === 200) {
			setTableData(response.data.customFields || []);
			setQueryData({
				...queryData,
				page: response.data.page,
				totalPage: response.data.totalPages,
				totalCount: response.data.totalCount
			});
			setIsLoading(false);
		} else {
			setIsLoading(false);
			toast.error(`An error occurred while fetching the data.`, {
				position: toast.POSITION.TOP_CENTER,
				autoClose: true,
				closeOnClick: false,
				draggable: true,
			});
		} 
	}

	const onTableActionBtnClick = async (rowItem, actionType) => {
		if (actionType === 'edit') {
			const permissionsSet = new Set(rowItem.permissions);
			const dateString = rowItem.value;
			const [day, month, year] = dateString.split('/');
			const date = new Date(year, month - 1, day);

			setInputData({
				...rowItem,
				id: rowItem._id,
				value: rowItem.inputType === 'date' ? date :  rowItem.value,
				permissions: allUserRoles.filter(item => permissionsSet.has(item.key))
			});
			setAddDialogueBoxOpen(true);
		} else if (actionType === 'delete') {
			setItemToDeleteAfterConfirm(rowItem._id)
			setDeleteConfirmModal(true);
		}
	}

	const handleItemDelete = async (itemToDelete) => {
		setIsLoading(true);
		const response = await service.deleteCustomFieldValues({ id: itemToDelete });
		if (response.status === 200) {
			toast.success("Item deleted successfully.", {
				position: toast.POSITION.TOP_CENTER,
				autoClose: true,
				closeOnClick: false,
				draggable: true,
			});
			// Remove the delted item from the table
			setTableData(tableData.filter(item => item._id !== itemToDelete));
		} else {
			toast.error(response.data?.message || "Item deleted successfully.", {
				position: toast.POSITION.TOP_CENTER,
				autoClose: true,
				closeOnClick: false,
				draggable: true,
			});
		}
		setDeleteConfirmModal(false);
		setIsLoading(false);
	}

	return (
		<StyledPaper elevation={2}>
			<StyledLink onClick={() => navigate(-1)}>
				<ArrowBackIosIcon style={{ fontSize: 'small' }} /> Go Back
			</StyledLink>
			<Grid container spacing={2} sx={{ width: '100%' }}>
				<Grid item xs={12} sx={{ maxWidth:'auto', display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
					<div></div> {/* Empty div to take up space */}
					<CreateButton tex={'Add'} onClick={() => { setAddDialogueBoxOpen(true) }} />
				</Grid>

				<Grid item xs={12}>
					<div style={{ maxWidth: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
						<Table
							data={tableData}
							columns={tableColumns}
							mobileOnlyColumns={mobileOnlyColumns}
							onButtonClick={onTableActionBtnClick}
							isLoading={isLoading}
						/>
						<Stack spacing={2} sx={{ marginTop: '25px' }}>
							<Pagination
								defaultPage={1}
								page={queryData.page}
								count={queryData.totalPages}
								variant="outlined"
								shape="rounded"
								color="primary"
								// onChange={handlePageChange}
							/>
						</Stack>
					</div>
				</Grid>
			</Grid>
			

			
			<CustomDialogueMedium
				open={addDialogueBoxOpen}
				onClose={() => {
					setAddDialogueBoxOpen(false)
					setInputData({
						id: '',
						label: '', // STRING: ipAddress, anyDeskUserName etc..
						inputType: '', // ENUM: longText, shortText, date, number 
						permissions: [],
						value: ''
					})
				}}
				headerText={`${inputData.id ? 'Update' : 'Add'} Custom Input Fields`}
				footerButtons={<SaveButton tex={inputData.id ? 'Update' : 'Save'} onClick={() => { handleCreateOrUpdate() }} />}
			>
				<Grid container spacing={2} sx={{ marginTop: '5px' }}>
					{
						!isLoading && 
						<>
							<Grid item xs={12}>
								<Typography variant='h6'>Configuration</Typography>
							</Grid>
							{/* Label  TextField*/}
							<Grid item xs={6}>
								<TextInput
									id="label"
									label="Label"
									required
									value={inputData.label}
									onChange={handleLabelChange}
									error={!inputData.label}
									fullWidth
								/>
							</Grid>
							{/* Input Type Select Box */}
							<Grid item xs={6}>
								<SelectInput
									label={'InputType'}
									value={inputData.inputType}
									required
									onChange={handleInputTypeChange}
									options={[{ key: 'longText', name: 'Long Text' }, { key: 'shortText', name: 'Short Text' }, { key: 'number', name: 'Number' }, { key: 'date', name: 'Date' },]}
								/>
							</Grid>
							{/* Multi-Select */}
							<Grid item xs={12}>
								<Autocomplete
									multiple
									id="permissions"
									options={allUserRoles}
									getOptionLabel={(option) => option.name}
									value={inputData.permissions}
									onChange={handlePermissionsChange}
									renderInput={(params) => (
										<TextField {...params} variant="standard" label="Permissions" placeholder="Roles" />
									)}
									disableCloseOnSelect
								/>
							</Grid>

							<Grid item xs={12}>
								<Typography variant='h6'>Example</Typography>
							</Grid>
							<Grid item xs={12} style={{ backGround: '#e5f6fd', padding: '15px', borderRadius: '8px', display: 'flex', justifyContent: 'flex', alignItems: 'flex' }}>
								{
									['longText', 'shortText', 'number'].includes(inputData.inputType) &&
									<>
										<TextField
											type={inputData.inputType === 'number' ? 'number' : 'text'}
											label={inputData.label}
											value={inputData.value}
											multiline={inputData.inputType === 'longText'}
											minRows={2}
											fullWidth={inputData.inputType === 'longText'}
											onChange={(e) => setInputData({ ...inputData, value: e.target.value })}
										/>
									</>
								}
								{
									inputData.inputType === 'date' &&
									<>
										<MUIDatePicker
											label={inputData.label}
											value={isNaN(Date.parse(inputData.value)) ? null : new Date(inputData.value)}
											onChange={(e) => {
												const dateString = e?.toLocaleDateString('en-GB') || ''; // Convert to 'dd/mm/yyyy' format
												setInputData({ ...inputData, value: dateString })
											}}
											onClear={() => {
												setInputData({ ...inputData, value: null })
											}}
											timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone} // Set timezone dynamically
										/>
									</>
								}
							</Grid>
						</>
					}
					{isLoading && 
						<>
							<Grid item xs={12}>
								<Skeleton variant="text" />
							</Grid>
							<Grid item xs={6}>
								<Skeleton variant="rectangular" height={56} />
							</Grid>
							<Grid item xs={6}>
								<Skeleton variant="rectangular" height={56} />
							</Grid>
							<Grid item xs={12}>
								<Skeleton variant="rectangular" height={56} />
							</Grid>
							<Grid item xs={12}>
								<Skeleton variant="text" />
							</Grid>
							<Grid item xs={12}>
								<Skeleton variant="rectangular" height={56} />
							</Grid>
						</>
					}
				</Grid>
			</CustomDialogueMedium>

			<CustomDialogueSmall
				open={deleteConfirmModal}
				onClose={() => {
					setDeleteConfirmModal(false);
					setItemToDeleteAfterConfirm(null);
				}}
				headerText={'Are you sure you want to delete this custom field?'}
				footerButtons={
					<>
						<DeleteButton
							onClick={() => {
								handleItemDelete(itemToDeleteAfterConfirm);
							}} />
					</>
				}
			/>

		</StyledPaper>
	)
}

export default CustomFields;