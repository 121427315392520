import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Divider,
    Paper,
    useMediaQuery,
    useTheme,
    Grid,
    InputLabel,
    TextField,
    Skeleton,
    Stack,
    Chip,
    Avatar,
    InputAdornment,
    Card,
    CardHeader,
    Popover,
    Typography,
    Box,
    FormHelperText,
    Button,
    SpeedDial,
    SpeedDialIcon,
    SpeedDialAction
} from '@mui/material';
import { toast } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import '../../../index.css'
import '../ticket.css'
import SelectInput from '../../../components/commons/SelectInput';
import MUIDatePicker from '../../../components/commons/DatePicker/DatePicker';
import { getCompaniesList } from '../../UserMangaement/Service';
import Autocomplete from '../../../components/commons/Autocomplete';
import FileUploader from '../../../components/FileUploader/FileUploader';
import TicketStatusSelector from '../../../components/commons/TicketStatusSelector';
import LabelSelector from '../../../components/commons/LabelSelector';
import MultipleUserSelector from '../../../components/commons/MultipleUserSelector/MultipleUserSelector';
import TicketPrioritySelector from '../../../components/commons/TicketPrioritySelector';
import { stringToColor } from '../../../utilities/commonUtils';
import { format } from 'date-fns';
import { ExportButton, ResetButton, SearchButton } from '../../../components/commons/Buttons';
import { ticketStatusForSearch } from '../../../constants/commonConstants';
import TicketSearchResultView from './QuickSearchResultView';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import SearchQueryComponent from './SearchQueryComponent';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import AdvancedTicketSearch from './AdvancedTicketSearch';
import { themeBackgroundPrimaryColor } from '../../../utilities/colorTheme';

const TicketQuickSearch = () => {
    const sessionUser = useSelector((state) => state.userData.data);
    const quickSearchData = useSelector((state) => state.ticketManagement.ticketQuickSearch)
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();
    const [companiesList, setCompaniesList] = useState([]);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const dialogContentStyle = {
        overflow: 'hidden',
        // maxHeight: !isMobile || !quickSearchData.isFullScreen  ? '85vh' : '',
        overflowY: 'auto',
    };

    useEffect(() => {
        getCompaniesList()
            .then((result) => {
                if (result?.data?.length) {
                    let tempArr = [];
                    result.data.forEach(item => {
                        tempArr.push({ value: item._id, label: item.companyName });
                    });
                    setCompaniesList(tempArr)
                } else if (result.data) {
                    setCompaniesList([{ value: result.data._id, label: result.data.companyName }]);
                }
            });
        
        if(isMobile) {
            dispatch({
                type: 'ticketManagement/updateFieldValues',
                payload: {
                    state: 'ticketQuickSearch',
                    field: 'isFilterComponentCollapsed',
                    value: true,
                },
            });
        }

    }, []);

    const actions = [

        { icon: <ManageSearchIcon />, name: 'Open Search Box', id: 1 },
        { icon: <OpenInFullIcon />, name: 'Open and Lock Search Box', id: 2 },
    ];


    return (
        <Dialog
            open={quickSearchData.isModalOpen}
            fullScreen={isMobile || quickSearchData.isFullScreen}
            fullWidth
            maxWidth={isMobile ? 'md' : 'xl'}
        >
            <div className="backdrop" />
            <div className="dialog-wrapper">
                <Paper className="dialog-paper">
                    <DialogTitle
                        className={'fixed-header'}
                        sx={{
                            backgroundColor: themeBackgroundPrimaryColor,
                            color: '#fff',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        
                            <>
                                <strong>{`Quick Search`}</strong>
                            </>

                        <div>
                            <IconButton
                                edge="end"
                                color="inherit"
                                aria-label="re-size-modal"
                                sx={{
                                    transition: 'background-color 0.3s, color 0.3s',
                                    '&:hover': {
                                        color: 'grey',
                                        backgroundColor: '#fff',
                                    },
                                }}
                                onClick={(() => {
                                    dispatch({
                                        type: 'ticketManagement/updateFieldValues',
                                        payload: {
                                            state: 'ticketQuickSearch',
                                            field: 'isFullScreen',
                                            value: !quickSearchData.isFullScreen,
                                        },
                                    });
                                })}
                            >
                                {quickSearchData.isFullScreen ? <CloseFullscreenIcon /> : <FullscreenIcon />}
                            </IconButton>
                            <IconButton
                                edge="end"
                                color="inherit"
                                aria-label="close"
                                sx={{
                                    transition: 'background-color 0.3s, color 0.3s',
                                    '&:hover': {
                                        color: 'grey',
                                        backgroundColor: '#fff',
                                    },
                                }}
                                onClick={(() => {
                                    dispatch({
                                        type: 'ticketManagement/updateFieldValues',
                                        payload: {
                                            state: 'ticketQuickSearch',
                                            field: 'isModalOpen',
                                            value: !quickSearchData.isModalOpen,
                                        },
                                    });
                                })}
                            >
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </DialogTitle>

                    <DialogContent className="dialog-content custom-scrollbar" style={dialogContentStyle}>
                        <DialogContent className="dialog-content custom-scrollbar" style={dialogContentStyle}>
                            <AdvancedTicketSearch />
                        </DialogContent>
                    </DialogContent>
                </Paper>
            </div>
        </Dialog >
    );
};

export default TicketQuickSearch;

