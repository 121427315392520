import React from 'react';
import { Paper, Link, Typography, Grid, IconButton } from '@mui/material';
import { styled } from "@mui/material/styles";
import { useNavigate } from 'react-router-dom';
import CustomFieldsIcon from '@mui/icons-material/AccountTree'; // Icon for 'Custom Fields'
import LabelsIcon from '@mui/icons-material/Label'; // Icon for 'Labels'

// Styled Paper for cards with luxurious feel and hover effects
const StyledPaper = styled(Paper)(({ theme }) => ({
    width: '100%',
    padding: theme.spacing(3),
    borderRadius: theme.shape.borderRadius * 2, // Smooth rounded corners
    backgroundColor: '#fff', // White card background to match the white main background
    border: '1px solid rgba(128, 128, 128, 0.3)', // Subtle gray border for separation from the background
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.05)', // Light shadow for clean look
    transition: 'transform 0.3s ease, box-shadow 0.3s ease, border 0.3s ease', // Smooth hover effect
    '&:hover': {
        transform: 'scale(1.03)', // Slight zoom-in effect on hover
        border: '1px solid #D4AF37', // Gold border on hover for luxury highlight
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.2)', // Enhanced shadow on hover
    },
}));

// Styled Link for text inside cards
const StyledLink = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    fontWeight: theme.typography.fontWeightBold,
    color: '#333', // Elegant dark grey for contrast
    fontSize: '1.2rem', // Larger font for better readability
    '&:hover': {
        color: '#D4AF37', // Gold color on hover to match the luxurious theme
    },
}));

// Styled Icon for the icons inside cards
const IconWrapper = styled(IconButton)(({ theme }) => ({
    color: '#D4AF37', // Gold icon color
    fontSize: '2.5rem', // Large icons for visibility
}));

// Grid container to align the cards responsively
const StyledGridContainer = styled(Grid)(({ theme }) => ({
    padding: theme.spacing(2), // Padding for comfortable spacing inside the grid
}));

// Header Typography to align with the luxury theme
const Header = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(3), // Space between header and cards
    fontWeight: theme.typography.fontWeightBold,
    color: '#333', // Dark gray for clean contrast with the background
    textAlign: 'center',
}));

function Settings() {
    const dictionaryItems = [
        {
            name: 'Custom Fields',
            path: '/Settings/CustomFields',
            icon: <CustomFieldsIcon />, // Icon for 'Custom Fields'
        },
        {
            name: 'Labels',
            path: '/', //ToDo: Update path when available
            icon: <LabelsIcon />, // Icon for 'Labels'
        }
    ];

    const navigate = useNavigate();

    return (
        <>
            <Header variant="h4">Settings</Header> {/* Header with dark gray color */}
            <StyledGridContainer container spacing={4} justifyContent="center">
                {dictionaryItems.map((item, index) => (
                    <Grid 
                        item 
                        xs={12}  // Full width (1 column) on extra-small devices
                        md={12}  // Full width (1 column) on medium devices
                        lg={6}   // Half width (2 columns) on large devices
                        xl={3}   // One-quarter width (4 columns) on extra-large devices
                        key={index}
                    > 
                        <StyledPaper elevation={3} onClick={() => navigate(item.path)}>
                            <Grid container alignItems="center" spacing={2}>
                                <Grid item>
                                    <IconWrapper>
                                        {item.icon}
                                    </IconWrapper>
                                </Grid>
                                <Grid item>
                                    <StyledLink>{item.name}</StyledLink>
                                </Grid>
                            </Grid>
                        </StyledPaper>
                    </Grid>
                ))}
            </StyledGridContainer>
        </>
    );
}

export default Settings;
