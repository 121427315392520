import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OPEN_IT_AMC_CREATE_ISSUE, SELECT_EDIT_DATA, UPDATE_IT_AMC_FILTER_DATA, fetchItAmcIssueWithQuery } from '../../app/ItAmc/ItAmcManagement';
import { Pagination, Stack } from '@mui/material';
import Table from '../../components/commons/Table/Table'
import { ticketStatus } from '../TicketManagement/TicketStatusArray';


const itAmcIssuesTable = [
    { field: "no", header: "No", type: "text", colSize: "small" },
    { field: "customIssueId", header: "Code", type: "text", colSize: "small" },
    { field: "issueHeading", header: "Issue Heading", type: "text", colSize: "large" },
    { field: "company", header: "Company", type: "text", colSize: "medium" },
    { field: "reportedUserName", header: "Reported User", type: "text", colSize: "medium" },
    { field: "status", header: "Issue Status", type: "text", colSize: "small" },
    
    { field: "attendedByUserName", header: "Attended By", type: "text", colSize: "medium" },
    { field: "attendedAt", header: "Attended At", type: "date", colSize: "small" },
    { field: "actions", header: "Edit", type: "button", colSize: "small" },
]
const mobileOnlyColumns = [
    { field: "no", header: "No", type: "text", colSize: "small" },
    { field: "customIssueId", header: "IssueNo", type: "text", colSize: "small" },
    { field: "issueHeading", header: "Model", type: "text", colSize: "large" },

    { field: "attendedAt", header: "Attended At", type: "date", colSize: "small" },
    { field: "actions", header: "Edit", type: "button", colSize: "small" },
]



const ListItAmcIssues = () => {
    const dispatch = useDispatch()

    const franchiseChange = useSelector((state) => state.userData.data.franchise)
    const itAmcIssuesList = useSelector(state => state.itAmcManagement.itAmcIssuesList);
    const itAmcIssueFilter = useSelector(state => state.itAmcManagement.itAmcIssuesFilter)
    const itAmcManagement = useSelector(state => state.itAmcManagement)

    const totalPages = useSelector(state => state.itAmcManagement.totalPages)
    useEffect(() => {
        dispatch(fetchItAmcIssueWithQuery(itAmcIssueFilter))
    }, [itAmcIssueFilter, franchiseChange])

    const getTableData = (data, index) => ({
        ...data,
        company: data.company.companyName,
        status: ticketStatus.find(stat => stat.value === data.status).label ?? "",
        no: ((itAmcIssueFilter.currentPage - 1) * itAmcIssueFilter.limit) + (index + 1)
    })
    const handlePageChange = (event, newPage) => {
        dispatch(UPDATE_IT_AMC_FILTER_DATA({ currentPage: newPage }));
    };
    const handleEditIssue = (val) => {

        dispatch(SELECT_EDIT_DATA(val))
    }
    return (
        <div>
            <Table
                data={itAmcIssuesList.map(getTableData) || []}
                columns={itAmcIssuesTable}
                mobileOnlyColumns={mobileOnlyColumns}
                onButtonClick={handleEditIssue}
                isLoading={itAmcManagement.Loading}
                firstColumnSticky={true}
            />
            <Stack spacing={2} sx={{ marginTop: '25px' }}>
                <Pagination
                    defaultPage={1}
                    page={itAmcIssueFilter.currentPage}
                    count={totalPages}
                    variant="outlined"
                    shape="rounded"
                    color="primary"
                    onChange={handlePageChange}
                />
            </Stack>
        </div>
    );
}

export default ListItAmcIssues;
