import React, { useEffect, useState } from 'react';
import CustomDialogueMedium from '../../components/commons/CustomDialogueMedium';
import { Avatar, Box, Card, CardContent, CardHeader, Chip, Grid, IconButton, InputAdornment, Paper, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { OPEN_IT_AMC_CREATE_ISSUE, SELECT_EDIT_DATA, addComment, updateIssue, updateIssueUploads } from '../../app/ItAmc/ItAmcManagement';
import { getCompaniesList, getCompanyLocations } from '../UserMangaement/Service';
import Autocomplete from '../../components/commons/Autocomplete';
import ItamcIssueStatusSelector from '../../components/commons/ItamcIssueStatusSelector';
import SelectBox from '../../components/commons/SelectInput';
import MultipleUserSelector from '../../components/commons/MultipleUserSelector/MultipleUserSelector';
import FileUploader from '../../components/FileUploader/FileUploader';
import { ResetButton, SaveButton } from './../../components/commons/Buttons';
import { SendRounded } from '@mui/icons-material';
import { stringToColor } from '../../utilities/commonUtils';
import { toast } from 'react-toastify';

const EditItAmcIssue = () => {
    const sessionUser = useSelector((state) => state.userData.data)
    const theme = useTheme();
    const dispatch = useDispatch();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isModalOpen = useSelector(state => state.itAmcManagement.isModalOpen);
    const editData = useSelector(state => state.itAmcManagement.editData);
    const itAmcManagement = useSelector(state => state.itAmcManagement)
    const [companiesList, setCompaniesList] = useState([]);
    const [companyLocationsList, setCompanyLocationsList] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});
    const [toggleCommentsAndActivities, setToggleCommentsAndActivities] = useState('Internal Comments')
    const [issueData, setIssueData] = useState(null)
    const [newComment, setNewComment] = useState('')



    useEffect(() => {
        if (editData) {
            console.log("editing data", issueData)
            setIssueData(editData)
        }
    }, [editData, isModalOpen])
    useEffect(() => {
        if (isModalOpen === 'update') {
            getCompaniesList()
                .then((result) => {
                    if (result?.data?.length) {
                        let tempArr = [];
                        result.data.forEach(item => {
                            tempArr.push({ value: item._id, label: item.companyName, type: item.type });
                        });
                        setCompaniesList(tempArr);
                    } else if (result.data) {
                        setCompaniesList([{ value: result.data._id, label: result.data.companyName, type: result?.data?.type }]);
                    }
                });
        }
    }, [isModalOpen]);
    const getSelectedCompaniesLocations = async () => {
        try {
            const locationsList = await getCompanyLocations(issueData?.company?.value);
            setCompanyLocationsList(locationsList?.data?.map((item) => ({ value: item.id, label: item.locationName })) || []);
        } catch (error) {
            setCompanyLocationsList([]);
            console.error(`Failed to get locations: ${error.message}`);
        }
    };
    function formatDate(dateTimeString) {
        return format(new Date(dateTimeString), 'dd/MM/yyyy hh:mm a'); // Format the date and time
    }
    useEffect(() => {
        if (issueData && issueData.company) {
            getSelectedCompaniesLocations();
        }
    }, [issueData?.company, issueData?.company?.value]);
    const handleChangeInput = (e) => {
        setIssueData(prev => ({ ...prev, [e.target.name]: e.target.value }))
    }
    const handleModalClose = () => { dispatch(OPEN_IT_AMC_CREATE_ISSUE(false)) }
    const saveUploadedFilesInRedux = (fileData) => {

        dispatch(updateIssueUploads({
            _id: issueData._id,
            uploads: [...issueData.uploads, fileData]
        })).then((res) => {
            if (!res.error) {
                setIssueData(prev => ({ ...prev, uploads: [...prev.uploads, fileData] }))
            }
        })
        // dispatch(UPDATE_IT_AMC_UPDATE_DATA({
        //     name: "uploads",
        //     value: [...issueData.uploads, fileData]
        // }));
    };

    const removeUploadedFileFromRedux = (fileName) => {
        if (fileName) {
            // dispatch(UPDATE_IT_AMC_UPDATE_DATA({
            //     name: "uploads",
            //     value: issueData.uploads?.filter(item => item.filename !== fileName)
            // }));
            dispatch(updateIssueUploads({
                _id: issueData._id,
                uploads: issueData.uploads.filter(item => item.filename !== fileName)
            })).then((res) => {
                if (!res.error) {
                    setIssueData(prev => ({ ...prev, uploads: prev.uploads?.filter(item => item.filename !== fileName) }))
                }
            })
        }
    };

    const validateForm = () => {
        let errors = {};
        let multInp = [];
        if (!issueData.issueHeading) {
            multInp.push("Issue Heading")
            errors.issueHeading = "Issue Heading is required."
        };
        if (!issueData.company) {
            multInp.push("Company")
            errors.company = "Company is required."
        };
        if (!issueData.reportedUsers?.length) {
            multInp.push("Reported Users")
            errors.reportedUsers = "At least one Reported User is required."
        };
        if (!issueData.status) {
            multInp.push("Status")
            errors.reportedUsers = "Please Select Status of the issue"
        };
        if (!issueData.attendedAt) {
            multInp.push("attendedAt")
            errors.reportedUsers = "Please Add a attended Date "
        };
        setValidationErrors(errors);
        const errorsOccured = Object.keys(errors).length === 0
        if (!errorsOccured) {
            toast.error("Please Enter " + multInp.join(", "))
        }
        return errorsOccured;
    };
    const handleSubmit = () => {
        const validation = validateForm()
        if (validation) {
            // Dispatch a submit action or any other logic here
            dispatch(updateIssue(issueData))
        }
    };
    const footerButtons = (
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: ".5rem" }}>
            <SaveButton onClick={handleSubmit} />
        </Box>
    );
    return (
        <CustomDialogueMedium
            open={isModalOpen === 'update'}
            onClose={handleModalClose}
            headerText={"Update Issue"}
            footerButtons={footerButtons}
            isLarge={!isMobile}
        >

            {!itAmcManagement.Loading && issueData && <Grid container sx={{ padding: "1rem" }} spacing={2}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Issue Heading"
                            required
                            value={issueData?.issueHeading}
                            name={'issueHeading'}
                            onChange={handleChangeInput}
                            error={!!validationErrors.issueHeading}
                            helperText={validationErrors.issueHeading}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <TextField
                            fullWidth
                            label="Attended At"
                            required
                            type='date'
                            value={issueData.attendedAt}
                            name={'attendedAt'}
                            onChange={handleChangeInput}
                            error={!!validationErrors.attendedAt}
                            helperText={validationErrors.attendedAt}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Description"
                            name={'description'}
                            multiline
                            value={issueData?.description}
                            minRows={3}
                            onChange={handleChangeInput}
                        />
                    </Grid>
                </Grid>
                <Grid sx={{ marginTop: "1rem" }} container spacing={1}>
                    <Grid item xs={isMobile ? 12 : 6}>
                        <Autocomplete
                            default={null}
                            label="Company"
                            name='company'
                            onChange={handleChangeInput}
                            options={companiesList}
                            value={issueData?.company ?? null}
                            error={!!validationErrors.company}
                            helperText={validationErrors.company}
                        />
                    </Grid>
                    {issueData?.company && companyLocationsList.length > 0 && <Grid item xs={isMobile ? 12 : 6}>
                        <Autocomplete
                            default={null}
                            label="Company Location"
                            name='companyLocation'
                            onChange={handleChangeInput}
                            options={companyLocationsList}
                            required={false}
                            value={issueData?.companyLocation ?? null}
                        />
                    </Grid>}
                </Grid>
                <Grid sx={{ marginTop: "1rem" }} container spacing={1}>
                    <Grid item xs={isMobile ? 12 : 6}>
                        <ItamcIssueStatusSelector
                            name={'status'}
                            label="Status"
                            value={issueData.status}
                            onChange={handleChangeInput}
                        // ticketState={'existing'}
                        />
                    </Grid>
                    <Grid item xs={isMobile ? 12 : 6}>
                        <SelectBox
                            label={'Payment Status'}
                            name={'paymentStatus'}
                            value={issueData?.paymentStatus}
                            required
                            default={'notRequired'}
                            onChange={handleChangeInput}
                            options={[{ key: 'required', name: 'Payment Required' }, { key: 'notRequired', name: 'Payment Not Required' }, { key: 'paid', name: 'Paid' }, { key: 'paymentPending', name: 'Payment Pending' }]}
                        />
                    </Grid>
                </Grid>
                <Grid sx={{ marginTop: "1rem" }} container spacing={2}>
                    <Grid item xs={12}>
                        <MultipleUserSelector
                            label={'Reported Users'}
                            value={issueData?.reportedUsers}
                            required
                            error={!issueData?.reportedUsers?.length || !!validationErrors.reportedUsers}
                            helperText={validationErrors.reportedUsers}
                            companyId={issueData?.company?.value}
                            onChange={(newValue) => {
                                setIssueData(prev => ({ ...prev, reportedUsers: newValue }))
                                // dispatch(UPDATE_IT_AMC_UPDATE_DATA({ name: "reportedUsers", value: newValue }))
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MultipleUserSelector
                            label={'Attended By'}
                            value={issueData?.attendedBy}
                            companyId={issueData?.company?.value}
                            onChange={(newValue) => {
                                setIssueData(prev => ({ ...prev, attendedBy: newValue }))
                                // dispatch(UPDATE_IT_AMC_UPDATE_DATA({ name: "attendedBy", value: newValue }))
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ marginBottom: '20px' }}>
                        <FileUploader
                            setAdminOnly={true}
                            onFileUploadSuccess={saveUploadedFilesInRedux}
                            onDeleteFile={removeUploadedFileFromRedux}
                            destinationParentFolder='ItAmcIssues'
                            destinationSubFolder={issueData._id}
                            savedFiles={issueData?.uploads || []}
                            disabled={false}
                        />
                    </Grid>
                    <Grid item xs={isMobile ? 12 : 6}>
                        {/* CHIPS TO SWITCH B/W COMMENTS & ACTIVITIES */}
                        <Stack direction="row" spacing={1} >
                            {   // Render activities only the current session user is a superAdmin
                                (sessionUser.userType === 'Admin') && (
                                    <Chip label="Internal Comments" sx={{ borderRadius: '7px', backgroundColor: toggleCommentsAndActivities === 'Internal Comments' ? '#8EACCD' : '', fontWeight: 600 }} onClick={() => setToggleCommentsAndActivities('Internal Comments')} />
                                )
                            }
                            {/* <Chip label="Customer Comments" sx={{ borderRadius: '7px', backgroundColor: toggleCommentsAndActivities === 'Customer Comments' ? '#8EACCD' : '', fontWeight: 600 }} onClick={() => setToggleCommentsAndActivities('Customer Comments')} /> */}
                            {   // Render activities only the current session user is a superAdmin
                                (sessionUser.role === 'coreAdmin' || sessionUser.role === 'superAdmin' || sessionUser.role === 'technicalAdmin' || sessionUser.role === 'technician') && (
                                    <Chip label="Activities" sx={{ borderRadius: '7px', backgroundColor: toggleCommentsAndActivities === 'activities' ? '#8EACCD' : '', fontWeight: 600 }} onClick={() => setToggleCommentsAndActivities('activities')} />
                                )
                            }
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        {toggleCommentsAndActivities === 'Internal Comments' &&
                            <>
                                <Stack direction={'row'} spacing={1.5} sx={{ marginTop: '10px' }}>
                                    <Avatar
                                        alt="User Avatar"
                                        src={sessionUser.photoURL}
                                        style={{
                                            marginRight: '8px',
                                            marginTop: '4px',
                                            backgroundColor: sessionUser.photoURL
                                                ? 'transparent'
                                                : sessionUser.displayName
                                                    ? stringToColor(sessionUser.displayName)
                                                    : stringToColor(`${sessionUser.firstName} ${sessionUser.lastName}`),
                                        }}
                                    >
                                        {!sessionUser.photoURL && (sessionUser.displayName || sessionUser.firstName) ? (
                                            sessionUser.displayName
                                                ? sessionUser.displayName[0]
                                                : sessionUser.firstName[0]
                                        ) : null}
                                    </Avatar>

                                    {(
                                        <TextField
                                            id="new-comment-add-textbox"
                                            label="Add a Comment"
                                            fullWidth
                                            multiline
                                            minRows={2}
                                            maxRows={10}
                                            value={newComment}
                                            onChange={(e) => {
                                                setNewComment(e.target.value)
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment
                                                        position="end"
                                                        onClick={() => {
                                                            dispatch(addComment({ _id: issueData._id, comment: newComment }))
                                                                .then((res) => {
                                                                    if (!res.error) {
                                                                        setNewComment("")
                                                                        setIssueData(prev => ({ ...prev, internalComments: res.payload.updatedData.internalComments }))
                                                                    }
                                                                })

                                                        }}
                                                    >
                                                        <IconButton>
                                                            <SendRounded />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />)
                                    }
                                </Stack>
                                <Grid item xs={12}>
                                    {
                                        issueData?.internalComments
                                            .slice()
                                            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                                            .map((comment, index) => (
                                                <Card
                                                    key={`card-${index}`}
                                                    sx={{
                                                        margin: 0.5,
                                                        backgroundColor: 'lightgray', // Customize the background color
                                                        border: '1px solid #ccc', // Add a border
                                                        borderRadius: '4px', // Add some border radius
                                                        '&:hover': {
                                                            backgroundColor: '#d9d9d9', // Change background color on hover
                                                        },
                                                    }}
                                                >
                                                    <CardHeader
                                                        avatar={
                                                            <Avatar
                                                                alt="Commented User"
                                                                src={comment?.user?.photoURL}
                                                                sx={{
                                                                    marginRight: '8px',
                                                                    marginTop: '4px',
                                                                    backgroundColor: comment?.user?.photoURL
                                                                        ? 'transparent'
                                                                        : comment?.user?.displayName
                                                                            ? stringToColor(comment.user.displayName)
                                                                            : stringToColor(`${comment?.user?.firstName} ${comment?.user?.lastName}`),
                                                                }}
                                                            >
                                                                {!comment?.user?.photoURL && (comment?.user?.displayName || comment?.user?.firstName) ? (
                                                                    comment?.user?.displayName
                                                                        ? comment?.user?.displayName[0]
                                                                        : comment?.user?.firstName[0]
                                                                ) : null}
                                                            </Avatar>
                                                        }
                                                        title={comment?.user?.displayName ?? `${comment?.user?.firstName} ${comment?.user?.lastName}`}
                                                        subheader={formatDate(comment.createdAt)} // Format the date and time
                                                    />
                                                    <CardContent sx={{ paddingTop: '0' }}> {/* Reduce the top padding */}
                                                        <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
                                                            {comment.comment}
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            ))}
                                </Grid>
                            </>
                        }
                        {toggleCommentsAndActivities === 'activities' && (
                            <>
                                {/* Activities Section */}
                                <Grid item xs={12}>
                                    <Box>
                                        {issueData.activities?.length ? (
                                            issueData.activities
                                                .slice()
                                                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                                                .map((log, index) => (
                                                    <Paper
                                                        key={`activity-${index}`}
                                                        sx={{
                                                            p: 2,
                                                            m: 1,
                                                            backgroundColor: 'lightgray', // Customize the background color
                                                            border: '1px solid #ccc', // Add a border
                                                            borderRadius: '4px', // Add some border radius
                                                            '&:hover': {
                                                                backgroundColor: '#d9d9d9', // Change background color on hover
                                                            },
                                                        }}
                                                    >
                                                        <Typography variant="body2" color="textSecondary">
                                                            {new Date(log.createdAt).toLocaleString()} {/* Format the date and time */}
                                                        </Typography>
                                                        <Typography variant="body1">{log.activityString}</Typography>
                                                    </Paper>
                                                ))
                                        ) : (
                                            <Typography variant="body2" color="textSecondary">
                                                No activities to display.
                                            </Typography>
                                        )}
                                    </Box>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Grid>
            </Grid>}
        </CustomDialogueMedium>
    );
}

export default EditItAmcIssue;
