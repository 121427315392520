import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const generateAndDownloadCsv = (exportAssetCsvDataForToner) => {

    const headers = ['Color', 'Brand', 'Model', 'Quantity'];

    // Initialize CSV data with styled headers
    let csvData = [headers.join(',')];

    exportAssetCsvDataForToner.forEach(item => {
        if (item.color && item.brands) {
            // Add color row
            const colorRow = ['"' + item.color + ' (' + item.totalQuantity + ')"', '', '', ''];
            csvData.push(colorRow.join(','));

            Object.values(item.brands).forEach(brand => {
                // Add brand row
                const brandRow = ['', '"' + brand.brand + ' (' + brand.totalQuantity + ')"', '', ''];
                csvData.push(brandRow.join(','));

                Object.values(brand.models).forEach(model => {
                    // Add model data
                    const modelRow = ['', '', model.model, model.quantity];
                    csvData.push(modelRow.join(','));
                });
            });
        } else if (item.total) {
            // Add total quantity as a separate row
            const totalRow = ['Total Quantity', '', '', item.total];
            csvData.push(totalRow.join(','));
        }
    });

    const csvBlob = new Blob([csvData.join('\n')], { type: 'text/csv' });

    // Create download link for the CSV file
    const url = URL.createObjectURL(csvBlob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'exportAsset.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

}



export const generateAndDownloadCsvForPrinterAndLaptop = (csvDataForPrinterAndLaptop, totalQuantity) => {

    const headers = ['Type', 'Brand', 'Model', 'Quantity'];

    // Initialize CSV data with headers
    let csvData = [headers.join(',')];

    // Iterate through each type (printer or laptop)
    Object.keys(csvDataForPrinterAndLaptop).forEach(type => {
        const typeData = csvDataForPrinterAndLaptop[type];
        // Add type row
        csvData.push([`${type} (${typeData.totalQuantity})`, '', '', ''].join(','));


        // Iterate through brands
        Object.keys(typeData.brands || {}).forEach(brandName => {
            const brand = typeData.brands[brandName];
            // Add brand row
            csvData.push(['', `${brand.brand} (${brand.totalQuantity})`, '', ''].join(','));

            // Iterate through models
            Object.keys(brand.models || {}).forEach(modelName => {
                const model = brand.models[modelName];
                // Add model row
                csvData.push(['', '', model.model, `${model.quantity} `].join(','));
            });
        });
    });

    const totalRow = ['Total Quantity', '', '', totalQuantity];
    csvData.push(totalRow.join(','));

    // Convert CSV data to string
    const csvString = csvData.join('\n');

    // Create a Blob object
    const csvBlob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

    // Create download link for the CSV file
    const url = URL.createObjectURL(csvBlob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'exportAssetPrinterAndLaptop.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};



export const generateAndDownloadPdf = (exportAssetPdfDataForToner) => {


    const headers = [{ text: 'Color', style: 'tableHeader', },
    { text: 'Brand', style: 'tableHeader', },
    { text: 'Model', style: 'tableHeader', },
    { text: 'Quantity', style: 'tableHeader', }];

    const tableBody = [];
    exportAssetPdfDataForToner.forEach(item => {
        if (item.color) {
            // Calculate rowspan for the color based on the total number of models across all brands
            const totalModels = Object.values(item.brands).reduce((acc, brand) => acc + Object.keys(brand.models).length, 0);
            let rowspanColor = totalModels || 1;

            // Iterate over each brand under the color
            Object.values(item.brands).forEach(brand => {
                const brandName = brand.brand;
                const models = Object.keys(brand.models);
                const rowspanBrand = models.length || 1;

                // Iterate over each model under the brand
                models.forEach((modelName, index) => {
                    const model = brand.models[modelName];
                    // Add a row for each model
                    const colorCell = index === 0 ? { text: `${item.color} (${item.totalQuantity})`, rowSpan: rowspanColor, alignment: 'center', bold: true, } : {};
                    const brandCell = index === 0 ? { text: `${brandName} (${brand.totalQuantity})`, rowSpan: rowspanBrand, color: '#00215E' } : {};
                    const modelCell = { text: modelName, color: '#803D3B' };
                    const quantityCell = { text: model.quantity.toString() };
                    tableBody.push([colorCell, brandCell, modelCell, quantityCell]);
                });
            });
        }
    });

    const lastItem = exportAssetPdfDataForToner[exportAssetPdfDataForToner.length - 1];
    const totalValue = lastItem.total;

    tableBody.push([
        { text: 'Total', colSpan: 3, alignment: 'center', bold: true, color: '#FF0000', fontSize: 14 },
        '', '', { text: totalValue.toString(), bold: true, color: '#FF0000', fontSize: 14 }
    ]);

    const docDefinition = {
        content: [
            { text: 'Export Asset Report', style: 'header', alignment: 'center' },
            {
                style: 'table',
                table: {
                    headerRows: 1,
                    widths: ['*', '*', '*', '*'],
                    body: [
                        headers,
                        ...tableBody
                    ]
                }
            }
        ],
        styles: {
            header: {
                fontSize: 18,
                bold: true,
                margin: [0, 0, 0, 10],
                decoration: 'underline',
            },
            table: {
                margin: [0, 5, 0, 15]
            },
            tableHeader: {
                bold: true,
                fontSize: 14,
                color: '#ffffff',
                fillColor: '#CDE8E5',
                alignment: 'center',

            },

        }
    };


    pdfMake.createPdf(docDefinition).download('asset_report.pdf');
};


export const generateAndDownloadPdfForPrinterAndLaptop = (exportAssetPdfDataForPrinterAndLaptop, totalQuantity, filters) => {


    const headers = [{ text: 'Type', style: 'tableHeader', },
    { text: 'Brand', style: 'tableHeader', },
    { text: 'Model', style: 'tableHeader', },
    { text: 'Quantity', style: 'tableHeader', }];

    const tableBody = [];
    const types = Object.keys(exportAssetPdfDataForPrinterAndLaptop);


    types.forEach(type => {
        const item = exportAssetPdfDataForPrinterAndLaptop[type];
        if (item) {
            // Calculate rowspan for the type based on the total number of models across all brands
            const totalModels = Object.values(item.brands).reduce((acc, brand) => acc + Object.keys(brand.models).length, 0);
            let rowspanType = totalModels || 1;

            // Iterate over each brand under the type
            Object.values(item.brands).forEach(brand => {
                const brandName = brand.brand;
                const models = Object.keys(brand.models);
                const rowspanBrand = models.length || 1;

                // Iterate over each model under the brand
                models.forEach((modelName, index) => {
                    const model = brand.models[modelName];
                    // Add a row for each model
                    const typeCell = index === 0 ? { text: `${type.toUpperCase()} (${item.totalQuantity})`, rowSpan: rowspanType, style: 'typeCelled', } : {};
                    const brandCell = index === 0 ? { text: `${brandName} (${brand.totalQuantity})`, rowSpan: rowspanBrand, color: '#00215E' } : {};
                    const modelCell = { text: modelName, color: '#803D3B' };
                    const quantityCell = { text: model.quantity.toString() };
                    tableBody.push([typeCell, brandCell, modelCell, quantityCell]);
                });
            });
        }
    });

    tableBody.push([
        { text: 'Total', colSpan: 3, alignment: 'center', bold: true, color: '#FF0000', fontSize: 14 },
        '', '', { text: totalQuantity.toString(), bold: true, color: '#FF0000', fontSize: 14 }
    ]);

    const docDefinition = {
        content: [
            { text: `Export Asset Report For ${capitalizeWords(filters.currentTab)}`, style: 'header', alignment: 'center' },
            {
                text: 'Filters :',
                margin: [0, 10, 0, 5],
                bold: true,
            },

            Object.entries(filters).some(([key, value]) => value && !['count', 'currentTab', 'page'].includes(key)) ?
                {
                    ol: Object.entries(filters)
                        .filter(([key, value]) => value && !['count', 'currentTab', 'page'].includes(key))
                        .map(([key, value]) => ({
                            text: `${capitalizeWords(key)}: ${value}`,
                            margin: [0, 0, 0, 5]
                        }))
                } :
                {
                    text: 'No filter applied',
                    margin: [0, 0, 0, 5]
                },

            {
                style: 'table',
                table: {
                    headerRows: 1,
                    widths: ['*', '*', '*', '*'],
                    body: [
                        headers,
                        ...tableBody
                    ]
                }
            }
        ],
        styles: {
            header: {
                fontSize: 18,
                bold: true,
                margin: [0, 0, 0, 10],
                decoration: 'underline',
            },
            table: {
                margin: [0, 5, 0, 15]
            },
            tableHeader: {
                bold: true,
                fontSize: 14,
                fillColor: '#7AB2B2',
                alignment: 'center',

            },
            typeCelled: {
                bold: true,
                fontSize: 12,
                alignment: 'center',
                verticalAlignment: 'center',
            },
            ol: {
                margin: [0, 15, 5, 10],
            },

        }
    };

    // console.log("docDefinition", docDefinition);


    pdfMake.createPdf(docDefinition).download('asset_report.pdf');
}

function capitalizeWords(str) {
    return str
        .split(/(?=[A-Z])/) // Split by capital letters
        .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
        .join(' '); // Join words with a space
}


export const generateAndDownloadPdfForTable = (data, filters) => {

    const headerRow = [
        { text: 'Sl No.', style: 'tableHeader' },
        { text: 'Code', style: 'tableHeader' },
        { text: 'Brand Name', style: 'tableHeader' },
        { text: 'Model', style: 'tableHeader' },
        { text: 'Color Counter', style: 'tableHeader' },
        { text: 'Black Coumter', style: 'tableHeader' },
        { text: 'Health Status', style: 'tableHeader' },
        { text: 'Parts Required', style: 'tableHeader' },
    ];

    const bodyRows = data.map((asset, index) => [
        index + 1,
        asset.machineCode || 'N/A',
        asset.machineName || 'N/A',
        asset.machineModel || 'N/A',
        asset.printerDetails?.counter?.black || 'N/A',
        asset.printerDetails?.counter?.color || 'N/A',
        { text: asset.healthStatus || 'N/A', style: "healthStatus" },
        { text: asset.isPartsRequired ? asset.partsDescription : 'No' ,style:asset.isPartsRequired ?"healthStatus" : "" },
    ]);



    const docDefinition = {
        content: [
            { text: 'Export Asset Report', style: 'header', alignment: 'center' },
            {
                text: 'Filters :',
                margin: [0, 10, 0, 5],
                bold: true,
            },

            Object.entries(filters).some(([key, value]) => value && !['count', 'currentTab', 'page'].includes(key)) ?
                {
                    ol: Object.entries(filters)
                        .filter(([key, value]) => value && !['count', 'currentTab', 'page'].includes(key))
                        .map(([key, value]) => ({
                            text: `${capitalizeWords(key)}: ${value}`,
                            margin: [0, 0, 0, 5]
                        }))
                } :
                {
                    text: 'No filter applied',
                    margin: [0, 0, 0, 5]
                },
            {
                table: {
                    headerRows: 1,
                    widths: ['auto', 'auto', '15%', 'auto', 'auto', 'auto', 'auto', '15%'],
                    body: [
                        headerRow,
                        ...bodyRows
                    ]
                }
            }
        ],
        styles: {
            header: {
                fontSize: 14,
                bold: true,
                margin: [0, 0, 0, 10],
                decoration: 'underline',
            },
            tableHeader: {
                bold: true,
                fontSize: 10,
                fillColor: '#7AB2B2',
                alignment: 'center',
            },
            ol: {
                margin: [0, 15, 5, 10],
            },
            healthStatus: {
                fontSize: 8,
            }

        }
    };

    // console.log("docDefinition", docDefinition);

    pdfMake.createPdf(docDefinition).download('export_asset_report.pdf');
};

export const downloadCompanyListPdf = (data) => {

    const headerRow = [
        { text: 'Sl No.', style: 'tableHeader' },
        { text: 'Company Name', style: 'tableHeader' },
        { text: 'Email', style: 'tableHeader' },
        { text: 'Phone', style: 'tableHeader' },
    ];

    const bodyRows = data.map((company, index) => [
        { text: index + 1, alignment: 'center' },
        company.companyName || '',
        company.companyEmail || '',
        company.companyPhone || '',
    ]);

    const docDefinition = {
        content: [
            { text: 'COMPANY REPORT', style: 'header', alignment: 'center' },
            {
                table: {
                    headerRows: 1,
                    widths: ['10%', 'auto', 'auto', 'auto'],
                    body: [
                        headerRow,
                        ...bodyRows
                    ]
                }
            }
        ],
        styles: {
            header: {
                fontSize: 18,
                bold: true,
                margin: [0, 0, 0, 10],
                decoration: 'underline',
            },
            tableHeader: {
                bold: true,
                fontSize: 12,
                fillColor: '#edede9',
                alignment: 'center',
                valign: 'middle'
            }
        }
    };

    pdfMake.createPdf(docDefinition).download('company_report.pdf');

}

export const downloadCompanyListCsv = (data) => {
    const headerRow = ['Sl No.', 'Company Name', 'Email', 'Phone'];

    const bodyRows = data.map((company, index) => [
        index + 1,
        company.companyName || '',
        company.companyEmail || '',
        company.companyPhone || ''
    ]);

    const csvContent = [
        headerRow.join(','),
        ...bodyRows.map(row => row.join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) { // Feature detection
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'company_report.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}
